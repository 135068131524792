import React from 'react'
import { Link } from 'gatsby'
import { FaTwitter, FaInstagram, FaYoutube, FaDev, FaTwitch, FaRss } from 'react-icons/fa'
import './Footer.css'

const Footer = () => (
  <footer>
    <div className='links'>
      <Link to='/'>Home</Link>
      <Link to='/about'>About</Link>
      <Link to='/privacy-policy'>Privacy Policy</Link>
      <Link to='/contact'>Contact</Link>
    </div>
    <div className='subscribe-links'>
      <a
        href='https://twitter.com/aspittel'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FaTwitter size='1.6em' title='Follow on Twitter' />
      </a>
      <a
        href='https://instagram.com/ali_writes_code'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FaInstagram size='1.6em' title='Follow on Instagram' />
      </a>
      <a
        href='https://dev.to/aspittel'
        target='_blank'
        rel='noopener noreferrer'
      >
        <FaDev size='1.6em' title='Follow on DEV' />
      </a>
      <a href='https://youtube.com/alispitteldev' target='_blank' rel='noopener noreferrer'>
        <FaYoutube size='1.6em' title='Follow on YouTube' />
      </a>
      <a href='https://twitch.tv/aspittel' target='_blank' rel='noopener noreferrer'>
        <FaTwitch size='1.6em' title='Follow on Twitch' />
      </a>

      <a href='/rss.xml' target='_blank' rel='noopener noreferrer'>
        <FaRss size='1.6em' title='Subscribe to RSS feed' />
      </a>
    </div>
    <div className='copyright'>
      <p>&copy; We Learn Code LLC. Created and written by{' '}
        <a href='https://alispit.tel'>Ali Spittel</a>.
      </p>
      <p>License: <a href='https://creativecommons.org/licenses/by-nc-nd/4.0/'>Attribution-NonCommercial-NoDerivatives 4.0 International</a></p>
    </div>
  </footer>
)

export default Footer
