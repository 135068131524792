import React from 'react'
import { Link } from 'gatsby'
import './RecentPosts.css'

const RecentPosts = props => {
  const posts = props.posts
  return (
    <section className='recent-posts'>
      {posts.edges.map((post, idx) => (
        <div key={post.node.fields.slug + 'recent'} className='recent-post'>
          <Link to={post.node.fields.slug}>
            <h2>{post.node.frontmatter.title}</h2>
          </Link>
          <span>{post.node.frontmatter.date}</span>
          <p>{post.node.excerpt}</p>
          <Link to={post.node.fields.slug} className='read-more'>
            Read More
          </Link>
          {idx % 3 === 0 && (
            <img src='./squiggle.svg' alt='' className='squiggle' />
          )}
          {idx % 3 === 2 && (
            <img src='./brown-dots.svg' alt='' className='brown-dots' />
          )}
        </div>
      ))}
    </section>
  )
}

export default RecentPosts
