import React from 'react'
import { Link } from 'gatsby'
import { FaTwitter, FaInstagram, FaYoutube, FaDev, FaTwitch } from 'react-icons/fa'
import './Nav.css'

export default () => (
  <div>
    <nav className='nav'>
      <div className='links'>
        <Link to='/about' className='start-here-link'>About</Link>
        <span className='more-links'>
          <Link to='/'>Home</Link>
          <Link to='/recent-posts'>Recent Posts</Link>
          <Link to='/subscribe'>Subscribe</Link>
          <Link to='/tutorials'>Tutorials</Link>
          <Link to='/beginners-guides'>Beginner's Guides</Link>
          <Link to='/advice'>Career Advice</Link>
          <Link to='/quick-tips' className='mobile-hide'>Quick Tips</Link>
          <Link to='/favorites'>Favorites</Link>
        </span>
      </div>
      <div className='subscribe-links'>
        <a
          href='https://twitter.com/aspittel'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaTwitter size='1.6em' title='Follow on Twitter' />
        </a>
        <a
          href='https://instagram.com/ali_writes_code'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaInstagram size='1.6em' title='Follow on Instagram' />
        </a>
        <a
          href='https://dev.to/aspittel'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaDev size='1.6em' title='Follow on DEV' />
        </a>
        <a href='https://youtube.com/alispitteldev' target='_blank' rel='noopener noreferrer'>
          <FaYoutube size='1.6em' title='Follow on YouTube' />
        </a>
        <a href='https://twitch.tv/aspittel' target='_blank' rel='noopener noreferrer'>
          <FaTwitch size='1.6em' title='Follow on Twitch' />
        </a>
      </div>
    </nav>
  </div>

)
