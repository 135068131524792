import React from 'react'
import Layout from '../components/Layout'
import RecentPosts from '../components/RecentPosts'

export default function Category(props) {
  return (
    <Layout location={props.location}>
      <div className='container post'>
        <h1>{props.pageContext.category}</h1>
        <RecentPosts posts={props.data.allMdx} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostByCategory($category: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(filter: { frontmatter: { category: { eq: $category } }}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
          }
        }
      }
    }
  }
`
