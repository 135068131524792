import React from 'react'

import Nav from '../Nav'
import Footer from '../Footer'

class Layout extends React.Component {
  render () {
    const { children } = this.props

    return (
      <>
        <Nav />
        <main>{children}</main>
        <Footer />
      </>
    )
  }
}

export default Layout
